import React from "react";
import Modal from "src/components/Shared/Modal";
import FormContent from "src/components/Forms/FormContent";

const ResponseDetailsModal = ({ isOpen = false, response = null, onCancel = () => {} }) => {
  return (
    <Modal
      size="xl"
      title="Response"
      secondaryTitle="View"
      isOpen={isOpen}
      onCancel={onCancel}
      defaultStyles={{
        overFlowYVisible: false,
      }}
      defaultOptions={{
        onSuccessButtonVisible: false,
        onCancelButtonVisible: false,
      }}>
      <div className="relative -mx-4 ">
        {response?.form_details?.sections &&
          response?.form_details?.sections?.length > 0 &&
          response?.form_details?.sections?.map((section, sectionIndex) => (
            <div
              className="flex w-full gap-4 px-6 py-2"
              key={section?.id || section?._id}>
              <div className="flex-shrink flex-grow">
                <div className="space-y-6">
                  {response?.form_details?.sections?.length > 1 && (
                    <div className="-mb-6 flex pl-1">
                      <div className="rounded-t-xl bg-highlightColor px-6 py-2 text-base font-semibold text-white">{`Section ${sectionIndex + 1} of ${response?.form_details?.sections?.length}`}</div>
                    </div>
                  )}
                  <FormContent
                    section={section}
                    answers={response?.answers}
                    from={"response"}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default ResponseDetailsModal;
